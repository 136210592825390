(function () {
  const version = "1.0.0";
  console.info(`Mention Ads v${version} loaded successfully.`);

  const main = async () => {
    const debugMode = document.location.hash.includes("mentionads=debug");

    const domain = document
      .querySelector("script[src^='https://cdn.mentionads.com']")
      .getAttribute("data-domain");

    try {
      const response = await fetch("https://api.mentionads.com/v1/keywords", {
        headers: {
          "content-type": "application/json;charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify({
          version,
          domain,
          url: document.location.href,
          body_text: document.body.innerText,
        }),
      });
      const json = await response.json();

      console.log(json);
    } catch (error) {
      console.error(error);
    }

    const modify = (keyword: string) => {
      const iterator = document.createNodeIterator(
        document.body,
        NodeFilter.SHOW_TEXT,
        (node: Node) => (node as Text).wholeText.includes(keyword) &&
          ["H1", "H2", "H3", "H4", "H5", "H6", "P", "SPAN"]
            .includes(node.parentElement?.tagName || "")
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_REJECT,
      );

      let node;
      while (node = iterator.nextNode()) {
        const link = document.createElement("a");
        link.href = `https://www.google.com/search?q=${keyword}`;
        link.innerText = keyword;
        link.target = "_blank";
        link.rel = "nofollow";

        if (debugMode) {
          link.style.border = "1px solid red";
        }

        const start = node.wholeText.indexOf(keyword)

        const range = document.createRange();
        range.selectNodeContents(node);
        range.setStart(node, start);
        range.setEnd(node, start + keyword.length);
        range.deleteContents();
        range.insertNode(link);
      }
    }

    const keywords = ["Pixel 9 Pro"];
    for (const keyword of keywords) {
      modify(keyword);
    }
  };

  document.readyState === "interactive"
    ? main()
    : document.addEventListener("DOMContentLoaded", main);
})();