(function () {
  const version = "1.0.0";
  console.info("Mention Ads", `v${version}`);

  interface IMention {
    keyword: string;
    asin: string;
    title: string;
    url: string;
  }

  const main = async () => {
    const debugMode = document.location.hash.includes("mentionads=debug");

    const domain = document
      .querySelector("script[src^='https://cdn.mentionads.com']")
      .getAttribute("data-domain");

    let mentions: IMention[] = [];
    try {
      const response = await fetch("https://api.mentionads.com/v1/search", {
        headers: {
          "content-type": "application/json;charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify({
          version,
          domain,
          url: document.location.href,
          body_text: document.body.innerText,
        }),
      });
      mentions = await response.json();
      if (debugMode) {
        console.debug("Mention Ads", mentions);
      }
    } catch (error) {
      console.error("Mention Ads", error);
    }

    const modify = (mention: IMention) => {
      const { keyword, title, url } = mention;

      const iterator = document.createNodeIterator(
        document.body,
        NodeFilter.SHOW_TEXT,
        (node: Node) => (node as Text).wholeText.includes(keyword) &&
          ["H1", "H2", "H3", "H4", "H5", "H6", "P", "SPAN", "B", "I", "STRONG", "EM", "LI", "UL", "OL"]
            .includes(node.parentElement?.tagName || "")
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_REJECT,
      );

      let node;
      while (node = iterator.nextNode()) {
        if (debugMode) {
          console.debug("Mention Ads", node.wholeText);
        }

        const link = document.createElement("a");
        link.innerText = keyword;
        link.title = title;
        link.href = url;
        link.target = "_blank";
        link.rel = "nofollow";

        if (debugMode) {
          link.style.border = "1px solid red";
        }

        const start = node.wholeText.indexOf(keyword);

        const range = document.createRange();
        range.selectNodeContents(node);
        range.setStart(node, start);
        range.setEnd(node, start + keyword.length);
        range.deleteContents();
        range.insertNode(link);
      }
    }

    for (const mention of mentions) {
      modify(mention);
    }
  };

  document.readyState === "interactive"
    ? main()
    : document.addEventListener("DOMContentLoaded", main);
})();